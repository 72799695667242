<template>
  <el-container>
    <el-main>
      <nav-bar />
      <el-row>
        <div style="height: 720px; text-align: center;">
          <h1>Not Found!</h1>
        </div>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import NavBar from 'components/layout/NavBar'

export default {
  name: 'NotFound',
  components: {
    NavBar
  }
}
</script>

<style>
</style>
