<template>
  <el-row class="el-menu-demo">
    <el-col :md="2">
      <ul role="menubar" class="el-menu--horizontal el-menu">
        <li role="menuitem" class="el-menu-item">
          <a href="/" style="color: #007bff;text-decoration-line: none">
            <img src="@/assets/img/icon/logo.png" class="logo" alt="img">
            tnb
          </a>
        </li>
      </ul>
    </el-col>
    <el-col :md="8">
      <el-menu
        mode="horizontal"
      >
        <el-menu-item index="1">
          <a href="/video" style="text-decoration-line: none">
            <span style="color: #007bff">分区</span>
          </a>
        </el-menu-item>
        <el-menu-item index="2">
          <a href="/shortvideo" style="text-decoration-line: none">
            <span style="color: #007bff">短视频</span>
          </a>
        </el-menu-item>
        <el-menu-item index="5">
          <a href="/playlist" style="text-decoration-line: none">
            <span style="color: #007bff">播放列表</span>
          </a>
        </el-menu-item>
      </el-menu>
    </el-col>
    <el-col :md="6">
      <ul class="el-menu--horizontal el-menu">
        <li class="el-menu-item">
          <el-autocomplete
            v-model="keyword"
            :fetch-suggestions="querySearchAsync"
            :placeholder="placeholder"
            clearable
            suffix-icon="el-icon-search"
            size="medium"
            :debounce="1000"
            @keyup.enter.native="onSearch"
            @select="onSearch"
          />
        </li>
      </ul>
    </el-col>
    <el-col :md="8">
      <ul class="el-menu--horizontal el-menu">
        <li class="el-menu-item">
          <el-dropdown v-if="user">
            <img
              :src="user.avatarUrl"
              class="el-avatar--circle el-avatar--medium"
              alt=""
            >
            <el-dropdown-menu v-if="user" slot="dropdown" class="iconsize">
              <el-dropdown-item
                icon="el-icon-user-solid"
                class="size"
                @click.native="goToProfile"
              >我的帐号</el-dropdown-item>
              <el-dropdown-item
                icon="el-icon-collection"
                class="size"
                @click.native="goToPlaylist"
              >播放列表</el-dropdown-item>
              <el-dropdown-item
                icon="el-icon-s-data"
                class="size"
                @click.native="goToHistory"
              >历史记录
              </el-dropdown-item>
              <el-dropdown-item
                icon="el-icon-s-home"
                class="size"
                @click.native="goToUserHome"
              >我的主页</el-dropdown-item>
              <el-dropdown-item
                icon="el-icon-error"
                class="size"
                @click.native="goToLogout"
              >退出</el-dropdown-item>
              <el-divider />
              <el-dropdown-item
                icon="el-icon-s-home"
                class="size"
                @click.native="goToExam"
              >我的考试</el-dropdown-item>
              <el-dropdown-item
                icon="el-icon-map-location"
                class="size"
                @click.native="goToMap"
              >我的地图</el-dropdown-item>
              <el-dropdown-item
                icon="el-icon-goods"
                class="size"
                @click.native="goToMall"
              >我的商城</el-dropdown-item>
              <el-dropdown-item
                icon="el-icon-chat-dot-round"
                class="size"
                @click.native="goToChat"
              >我的IM</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span
            v-else
            style="color: #007bff"
            @click="login"
          >登录</span>
        </li>
        <li class="el-menu-item" @click="goToTimeline">
          <el-badge class="item" :value="statusCount" :max="99">
            <span class="el-icon-view" style="color: #007bff">状态</span>
          </el-badge>
        </li>
        <li class="el-menu-item" @click="goToMessage">
          <el-badge class="item" :value="msgCount" :max="99">
            <span class="el-icon-bell" style="color: #007bff">消息</span>
          </el-badge>
        </li>
        <li class="el-menu-item">
          <el-button size="mini" type="upload" icon="el-icon-upload" @click="goToPublish">投稿</el-button>
        </li>
      </ul>
    </el-col>

    <!--登录弹窗-->
    <el-dialog
      title="用户登录"
      append-to-body
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <el-form ref="form" :model="userLogin">
        <el-form-item label="帐号">
          <el-input
            v-model="userLogin.principal"
            placeholder="请输入手机号或邮箱"
            style="width: 70%; padding-right: 2px"
            clearable
          />
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            v-model="userLogin.credential"
            placeholder="请输入验证码"
            style="width: 45%; padding-right: 2px"
          />
          <el-button :disabled="isBtn" @click="loginVerifyCode">{{ code }}</el-button>
        </el-form-item>
        <el-form-item label="图形验证码" label-width="90px">
          <el-image :src="captchaCode" @click="getCaptcha" />
          <el-input
            v-model="userLogin.captchaCode"
            placeholder="请输入图形验证码"
            style="width: 45%; padding-right: 2px"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="isLoading"
            @click.native="loginBtn"
          >登 录</el-button>
          <el-button type="plain" @click="register">注册/忘记密码?</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-row>
</template>

<script>
import { userMixin } from 'assets/js/mixin'
import { keywordSuggest } from '@/api/search'
import { getAuthedUser } from '@/utils/auth'
import { getUnreadCount } from '@/api/user'

export default {
  name: 'NavBar',
  mixins: [userMixin],
  data() {
    return {
      user: null,
      activeIndex: '1',
      restaurants: [],
      placeholder: '想要搜点神马呢',
      keyword: '',
      statusCount: 0,
      msgCount: 0
    }
  },
  created() {
    /* const userdata = Vue.$cookies.get('USERDATA')
    const userId = userdata.split(':')[0]*/
    const userInfo = getAuthedUser()
    if (userInfo !== null) {
      this.user = userInfo
      getUnreadCount().then(resp => {
        if (resp.code === 0) {
          this.msgCount = resp.data.total
        }
      })
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    // ****************************************************************************************************************
    // 重点：当框中的改变时触发该方法，elementui自动设置了防抖，参见debounce属性
    // queryString 为输入框中的值。cb为返回显示列表的回调函数
    querySearchAsync(queryString, cb) {
      if (queryString === '') {
        return
      }

      setTimeout(() => {
        keywordSuggest(queryString).then(res => {
          if (res.code === 0) {
            this.restaurants = res.data.map((item) => {
              return {
                value: item.keyword,
                rank: 1
              }
            })

            // 如果 cb 返回一个空数组, 那么模糊搜索输入建议的下拉选项会因为 length 为 0 而消失
            // cb([])
            cb(this.restaurants)
            // eslint-disable-next-line no-empty
          } else {
          }
        })
      }, 500)
    },
    // select 事件或 enter 键事件
    onSearch() {
      console.log('回车事件')
      // 正则去空格
      if (this.keyword.replace(/\s*/g, '')) {
        this.toSearchPage()
      } else {
        this.$message({
          showClose: true,
          message: '不能为空！',
          type: 'warning'
        })
      }
    },
    // 跳转搜索页面，传递搜索框的参数
    toSearchPage() {
      const currentPath = this.$route.path
      if (currentPath === '/search') {
        this.$router.push({
          path: '/search',
          query: {
            keyword: this.keyword,
            pageNumber: 1
          }
        })
        this.$router.go(0)
      } else {
        const routeUrl = this.$router.resolve({
          path: '/search',
          query: {
            keyword: this.keyword,
            pageNumber: 1
          }
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    // ****************************************************************************************************************
    login() {
      const path = '/login'
      if (this.$route.path === path) {
        this.$router.go(0)
        return
      }
      this.$router.push(path)
    },
    register() {
      console.log('帐号注册')
    },
    // ****************************************************************************************************************
    goToPlaylist() {
      const path = '/post/album'
      if (this.$route.path === path) {
        this.$router.go(0)
        return
      }
      this.$router.push(path)
    },
    goToDisk() {
      const path = '/disk'
      if (this.$route.path === path) {
        this.$router.go(0)
        return
      }
      this.$router.push(path)
    },
    goToProfile() {
      const path = '/my'
      if (this.$route.path === path) {
        this.$router.go(0)
        return
      }
      this.$router.push(path)
    },
    goToTimeline() {
      const path = '/timeline'
      if (this.$route.path === path) {
        this.$router.go(0)
        return
      }
      this.$router.push(path)
    },
    goToUserHome() {
      const path = '/user/' + this.user.userId
      if (this.$route.path === path) {
        this.$router.go(0)
        return
      }
      this.$router.push(path)
    },
    goToHistory() {
      if (this.$route.path === '/post/history') {
        this.$router.go(0)
        return
      }
      this.$router.push('/post/history')
    },
    goToMessage() {
      if (this.$route.path === '/my/message') {
        this.$router.go(0)
        return
      }
      this.$router.push('/my/message')
    },
    goToPublish() {
      if (this.$route.path === '/post/video/list') {
        this.$router.go(0)
        return
      }
      this.$router.push('/post/video/list')
    },
    // ************************************************************************
    // 我的考试
    goToExam() {
      if (this.$route.path === '/exam') {
        this.$router.go(0)
        return
      }
      this.$router.push('/exam')
    },
    // 我的地图
    goToMap() {
      if (this.$route.path === '/map') {
        this.$router.go(0)
        return
      }
      this.$router.push('/map')
    },
    // 我的商城
    goToMall() {
      if (this.$route.path === '/mall') {
        this.$router.go(0)
        return
      }
      this.$router.push('/mall')
    },
    // 我的IM
    goToChat() {
      if (this.$route.path === '/chat') {
        this.$router.go(0)
        return
      }
      this.$router.push('/chat')
    }
    // ************************************************************************
  }
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
}

.logo {
  width: 30px;
  position: relative;
}

.size {
  font-size: 16px;
}

.item {
  margin-top: 10px;
  margin-right: 10px;
}
</style>
